<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>保理户发放导入</span>
      </div>
    </div>
    <a-row :gutter="16" class="btn--container">
      <a-col class="gutter-row" :span="12" style="color: red; font-size: 20px">
        <!-- <a-button type="primary" @click="testUpload()">
          <a-icon type="download" />
          {{ l("上传文件") }}
        </a-button> -->
        温馨提示：先下载模板再导入保理户发放
      </a-col>
      <a-col class="gutter-row" :span="12" style="text-align: right">
        <a-button @click="downLoad()">
          <a-icon type="download" />
          {{ l("下载模板") }}
        </a-button>
      </a-col>
    </a-row>
    <a-row :gutter="16" class="upload--container">
      <a-col class="gutter-row" :span="24">
        <a-upload-dragger
          ref="uploadExcel"
          name="file"
          :multiple="false"
          :action="uploadUrl"
          accept=".xls,.xlsx"
          :headers="uploadHeaders"
          :before-upload="beforeUpload"
          :showUploadList="showUploadList"
          @change="handleChange"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">将文件拖到此处或点击上传</p>
          <p class="ant-upload-hint">只支持.xls,.xlsx文件格式上传。</p>
        </a-upload-dragger>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { AppConsts } from "@/abpPro/AppConsts";
import fileHelper from "@/shared/helpers/fileHelper";

import { fileDownloadService } from "@/shared/utils";

export default {
  mixins: [ModalComponentBase],
  name: "salary-prepaidccount-import-form",
  data() {
    return {
      taskId: "",
      spinning: false,
      fileList: [],
      uploadHeaders: {},
      uploadUrl: "",
      showUploadList: false,
    };
  },
  components: {},
  beforeCreate() {},
  created() {
    Object.assign(this.uploadHeaders, {
      Authorization: "Bearer " + abp.auth.getToken(),
    });
    this.fullData();
    this.uploadUrl =
      AppConsts.remoteServiceBaseUrl +
      "/api/services/app/DataCenter/ImportSalaryPrepaidAccount";
  },
  mounted() {},
  methods: {
    async handleChange(info) {
      this.spinning=true;
      const status = info.file.status;
      if (status !== "uploading") {
      }
      if (status === "done") {
        const res = info.file.response.result;
        this.spinning = false;
        if (
          res.fileInfo &&
          res.fileInfo.fileName &&
          res.fileInfo.fileToken &&
          res.fileInfo.fileType
        ) {
          this.$message.warning(res.message);
          fileDownloadService.downloadTempFile(res.fileInfo);
        } else {
          this.$message.success(`${info.file.name}导入成功.`);
          this.showUploadList = true;
          this.success(true);
        }
      } else if (status === "error") {
        this.spinning = false;
        // let message = info.file.response.error.message;
        this.$message.error(`${info.file.name}文件上传失败.`);
      }
    },
    //限制文件上传大小
    beforeUpload(file) {
      console.log(file);
      var fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isFile = !(fileName != "xlsx" && fileName != "xls");

      if (!isFile) {
        this.$message.error("文件格式必须是xls或xlsx!");
      }
      const isLt10M = file.size / 1024 / 1024 <= 10;
      if (!isLt10M) {
        this.$message.error("上传的文件必须小于 10MB！");
      }
      return isFile && isLt10M;
    },
    //点击下载模板
    downLoad() {
      fileHelper.downloadTemplateFile("保理户发放导入模板.xls");
    },
    SendToService(opts) {
      this.spinning = true;
      let options = {
        url: this.$apiUrl + opts.url,
        method: opts.method,
        data: opts.data,
        params: opts.params,
        headers: { "Content-Type": "application/json-patch+json" },
      };
      this.$api
        .request(options)
        .then((res) => {
          this.spinning = false;
          if (opts.complete && typeof opts.complete == "function") {
            opts.complete(res);
          }
          if (res.status == 200) {
            if (opts.success && typeof opts.success == "function") {
              opts.success(res.data);
            }
          } else {
            abp.message.error(res.statusText);
          }
        })
        .catch((e) => {
          this.spinning = false;
          if (opts.complete && typeof opts.complete == "function") {
            opts.complete(e);
          }
          console.error(e);
        });
    }
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin: 0 20px !important;
}
.upload--container {
  margin: 20px !important;
}
</style>
