<template>
  <a-spin :spinning="spinning">
    <a-tabs tab-position="left" style="background: #fff">
      <a-tab-pane key="1" tab="发放明细">
        <ele-table
          :columns="columns"
          :table-data="tableData"
          :total-items="totalItems"
          :is-full="true"
          :actionsType="actionsType"
          :current-page="pageNumber"
          :hideRowSelection="true"
          @emitRefreshData="clearFilterAndRefresh"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange"
        >
          <a-row :gutter="24">
            <a-col class="gutter-row" :span="4">
              <a-select
                v-model="SearchDto.CustomerId"
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                placeholder="客户名称"
                allow-clear
                @select="customerSelectChange"
              >
                <a-select-option
                  v-for="item in Customers"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-range-picker
                :placeholder="['发放开始时间', '发放结束时间']"
                v-model="SearchDto.DateRange"
              />
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-input
                v-model.trim="SearchDto.RealName"
                placeholder="姓名"
                allow-clear
              />
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-input
                v-model.trim="SearchDto.BatchNo"
                placeholder="业务参考号"
                allow-clear
              />
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-input
                v-model.trim="SearchDto.TpsBatchNo"
                placeholder="结算批次号"
                allow-clear
              />
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-input
                v-model.trim="SearchDto.WithdrawOrderNo"
                placeholder="订单编号"
                allow-clear
              />
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-select
                v-model="SearchDto.IsWithdraw"
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                :options="channelOptions"
                placeholder="渠道"
                allowClear
              >
              </a-select>
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-select
                v-model="SearchDto.Status"
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                :options="StatusOptions"
                placeholder="状态"
                allowClear
              >
              </a-select>
            </a-col>
            <a-col :span="6">
              <a-select
                v-model="SearchDto.CompanyId"
                show-search
                :filter-option="filterOption"
                style="width: 100%"
                :placeholder="l('PlaceSelectPayCompany')"
                allowClear
              >
                <a-select-option
                  v-for="item in PayCompanys"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-range-picker
                :placeholder="['开始创建时间', '结束创建时间']"
                v-model="SearchDto.CreateDateRange"
              />
            </a-col>
            <a-col class="gutter-row" :span="6">
              <a-range-picker
                :placeholder="['最后更新开始时间', '最后更新结束时间']"
                v-model="SearchDto.UpdateDateRange"
              />
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-select
                v-model="SearchDto.FactoringStatus"
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                :options="FactoringStatusOptions"
                placeholder="保理状态"
                allowClear
              >
              </a-select>
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-button
                type="primary"
                @click="searchData"
                v-if="isGranted('Pages.DataCenter.SalaryPayNode.Query')"
              >
                {{ l("Search") }}
              </a-button>
              <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
              <a-button
                v-if="isGranted('Pages.DataCenter.SalaryPayNode.Export')"
                @click="exportToExcel()"
              >
                <a-icon type="file-excel" />
                <span>{{ l("Export") }}</span>
              </a-button>
            </a-col>
            <a-col class="gutter-row" :span="2" style="text-align: right">
              <a-button
                v-if="
                  isGranted(
                    'Pages.DataCenter.SalaryPayNode.ImportSalaryPrepaidAccount'
                  )
                "
                @click="openImportForm"
              >
                导入保理户发放
              </a-button>
            </a-col>
          </a-row>
        </ele-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="流水明细">
        <Accountlog></Accountlog>
      </a-tab-pane>
    </a-tabs>
    <a-modal
      centered
      :title="submitSalaryModal.title"
      :visible="submitSalaryModal.visible"
      :confirm-loading="submitSalaryModal.confirmLoading"
      :maskClosable="submitSalaryModal.maskClosable"
      :destroyOnClose="submitSalaryModal.destroyOnClose"
      :width="submitSalaryModal.width"
      @ok="submitSalaryModal.confirm"
      @cancel="submitSalaryModal.cancel"
      :cancelText="submitSalaryModal.cancelText"
      :okText="submitSalaryModal.okText"
    >
      <div style="text-align: center">
        <a-textarea
          style="width: 100%"
          v-model="submitSalaryModal.form.failedReason"
          placeholder="请输入拦截原因"
          allowClear
        />
      </div>
    </a-modal>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";
import { CommonServiceProxy } from "@/shared/common-service";
import { fileDownloadService } from "@/shared/utils";
import ImportForm from "./importform.vue";
import Accountlog from "./accountlog";
const customerIdLocalStorageKey = "datacenter_salarypay_list_customerId";
export default {
  name: "settlementModule",
  mixins: [AppComponentBase],
  components: { EleTable, Accountlog },
  data: function () {
    return {
      spinning: false,
      actionsType: [],
      SearchDto: {
        RealName: undefined,
        DateRange: [],
        CreateDateRange: [],
        UpdateDateRange: [],
        TpsBatchNo: undefined,
        BatchNo: undefined,
        CustomerId: undefined,
        WithdrawOrderNo: undefined,
        IsWithdraw: undefined,
        SkipCount: 0,
        MaxResultCount: 50,
        CompanyId: undefined,
        Status: undefined,
        FactoringStatus:undefined,
      },
      commonService: null,
      totalItems: 0,
      pageNumber: 1,
      pagerange: [1, 1],
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      columns: [],
      Customers: [],
      channelOptions: [
        {
          label: "线上",
          value: true,
        },
        {
          label: "线下",
          value: false,
        },
      ],
      PayCompanys: [],
      StatusOptions: [
        {
          label: "待处理",
          value: 0,
        },
        {
          label: "发放成功",
          value: 1,
        },
        {
          label: "发放失败",
          value: 2,
        },
        {
          label: "已重新发放",
          value: 3,
        },
        {
          label: "已驳回",
          value: 4,
        },
        {
          label: "发放中",
          value: 5,
        },
        {
          label: "已作废",
          value: 6,
        },
      ],
      FactoringStatusOptions:[
        {
          label: "待转账",
          value: 1,
        },
        {
          label: "转账中",
          value: 2,
        },
        {
          label: "转账成功",
          value: 3,
        },
        {
          label: "转账失败",
          value: 4,
        },
        {
          label: "手续费扣除失败",
          value: 5,
        },
        {
          label: "转账流程创建中",
          value: 6,
        },
      ],
      // 点击拦截弹出的modal
      submitSalaryModal: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 500,
        title: "",
        cancelText: "取消",
        okText: "确认",
        form: {
          batchNo: undefined,
          remark: undefined,
        },
        confirm: () => {
          this.interceptSalary(this.submitSalaryModal.form);
        },
        cancel: () => {
          this.submitSalaryModal.confirm = () => {};
          this.submitSalaryModal.confirmLoading = false;
          this.submitSalaryModal.visible = false;
          this.submitSalaryModal.form={
            batchNo: undefined,
            failedReason: undefined
          }
        },
      },
    };
  },
  methods: {
    initColumns() {
      this.columns = [
        {
          title: "身份证号",
          dataIndex: "idCard",
          align: "center",
          ellipsis: true,
        },
        {
          title: "手机号",
          dataIndex: "phone",
          align: "center",
          ellipsis: true,
        },
        {
          title: "合伙人姓名",
          dataIndex: "name",
          align: "center",
          ellipsis: true,
        },
        {
          title: "合伙人类型",
          dataIndex: "projectName",
          align: "center",
          ellipsis: true,
        },
        {
          title: "开户银行",
          dataIndex: "bankName",
          align: "center",
          ellipsis: true,
        },
        {
          title: "银行卡号",
          dataIndex: "bankNum",
          align: "center",
          ellipsis: true,
        },
        {
          title: "订单编号",
          dataIndex: "withdrawOrderNo",
          align: "center",
          ellipsis: true,
        },
        {
          title: "结算批次号",
          dataIndex: "tpsBatchNo",
          align: "center",
          ellipsis: true,
        },
        {
          title: "业务参考号",
          dataIndex: "batchNo",
          align: "center",
          ellipsis: true,
        },
        {
          title: "发放明细号",
          dataIndex: "payNo",
          align: "center",
          ellipsis: true,
        },
        {
          title: "结算金额",
          dataIndex: "amount",
          align: "center",
          ellipsis: true,
        },
        {
          title: "实付金额",
          dataIndex: "payAmount",
          align: "center",
          ellipsis: true,
        },
        {
          title: "发放时间",
          dataIndex: "payTime",
          align: "center",
          ellipsis: true,
          type: "time",
        },
        {
          title: "发放状态",
          dataIndex: "statusName",
          align: "center",
          ellipsis: true,
        },
        {
          title: "发放结果说明",
          dataIndex: "payResult",
          align: "center",
        },
        {
          title: "渠道",
          dataIndex: "isWithdraw",
          align: "center",
          ellipsis: true,
        },
        {
          title: "发放公司",
          dataIndex: "payCompany",
          align: "center",
          ellipsis: true,
        },
        {
          title: "数据来源",
          dataIndex: "source",
          align: "center",
          ellipsis: true,
        },
        {
          title: "流水来源",
          dataIndex: "busAccountLogSource",
          align: "center",
          ellipsis: true,
        },
        {
          title: "保理状态",
          dataIndex: "factoringStatusName",
          align: "center",
          ellipsis: true,
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          align: "center",
          type: "time",
          ellipsis: true,
        },
        {
          title: "最后更新时间",
          dataIndex: "lastModificationTime",
          align: "center",
          type: "time",
          ellipsis: true,
        },
        {
          title: "操作",
          dataIndex: "actions",
          fixed: "right",
          sorter: false,
          align: "center",
          width: 100,
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    initActionType() {
      let obj = {
        type: "sign-manage",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted(
                "Pages.DataCenter.SalaryPayNode.InterceptSalary"
              ),
              name: "发放拦截",
              icon: "close",
              fn: (data) => {
                this.submitSalaryModal.title =
                  "当前拦截的结算单号：" + data.tpsBatchNo;
                this.submitSalaryModal.form.batchNo = data.batchNo;
                this.submitSalaryModal.visible = true;
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    setDefaultDateRange() {
      const now = moment();
      const startDate = now.clone().subtract(7, "days");
      // 保持结束日期为当前日期
      const endDate = now;
      const formatDate = (date) => date.format("YYYY-MM-DD");
      // 将格式化后的日期放入DateRange数组
      this.SearchDto.DateRange = [formatDate(startDate), formatDate(endDate)];
    },
    /**
     * 获取所有客户
     * */
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    /**
     * 拦截发放
     * */
    async interceptSalary(data) {
      let options = {
        url: this.$apiUrl + "/api/services/app/DataCenter/InterceptSalary",
        method: "POST",
        data: data,
      };
      try {
        const res = await this.$api.request(options);
        if (res.data) {
          this.$message.success("拦截成功");
          this.submitSalaryModal.visible = false;
          this.searchData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    //获取所有公司
    getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Organization/GetSelectList",
          method: "GET",
        })
        .then((res) => {
          this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    /**
     * 获取分页
     */
    getData() {
      if (!this.SearchDto.CustomerId) {
        return false;
      }
      this.spinning = true;
      var parData = { ...this.SearchDto };
      if (parData.DateRange.length > 1) {
        parData.StartDate = moment(parData.DateRange[0]).format("YYYY-MM-DD");
        parData.EndDate = moment(parData.DateRange[1]).format("YYYY-MM-DD");
      }
      if (parData.CreateDateRange.length > 1) {
        parData.CreateStartDate = moment(parData.CreateDateRange[0]).format(
          "YYYY-MM-DD"
        );
        parData.CreateEndDate = moment(parData.CreateDateRange[1]).format(
          "YYYY-MM-DD"
        );
      }
      if (parData.UpdateDateRange.length > 1) {
        parData.UpdateStartDate = moment(parData.UpdateDateRange[0]).format(
          "YYYY-MM-DD"
        );
        parData.UpdateEndDate = moment(parData.UpdateDateRange[1]).format(
          "YYYY-MM-DD"
        );
      }
      let options = {
        url: this.$apiUrl + "/api/services/app/DataCenter/GetSalaryPayPaged",
        method: "GET",
        params: parData,
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.SearchDto.MaxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
              this.pageNumber * this.SearchDto.MaxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              return {
                ...item,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    searchData() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    clearFilterAndRefresh() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    /**
     * 导出为excel
     */
    async exportToExcel() {
      this.spinning = true;
      var parData = { ...this.SearchDto };
      if (parData.DateRange.length > 1) {
        parData.StartDate = moment(parData.DateRange[0]).format("YYYY-MM-DD");
        parData.EndDate = moment(parData.DateRange[1]).format("YYYY-MM-DD");
      }
      if (parData.CreateDateRange.length > 1) {
        parData.CreateStartDate = moment(parData.CreateDateRange[0]).format(
          "YYYY-MM-DD"
        );
        parData.CreateEndDate = moment(parData.CreateDateRange[1]).format(
          "YYYY-MM-DD"
        );
      }
      if (parData.UpdateDateRange.length > 1) {
        parData.UpdateStartDate = moment(parData.UpdateDateRange[0]).format(
          "YYYY-MM-DD"
        );
        parData.UpdateEndDate = moment(parData.UpdateDateRange[1]).format(
          "YYYY-MM-DD"
        );
      }
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/DataCenter/ExportSalaryPay",
          params: parData,
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    /**
     * 导入保理户发放
     */
    openImportForm() {
      ModalHelper.create(ImportForm, {
        width: "500px",
      }).subscribe((res) => {
        this.searchData();
      });
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // tableѡ���¼�
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // ��ҳ
    updatePageChange(newV) {
      let { page, pageSize, skipCount } = newV;
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * pageSize;
      this.SearchDto.MaxResultCount = pageSize;
      this.getData();
    },
    clearAndGetData() {
      this.SearchDto.RealName = undefined;
      this.SearchDto.DateRange = [];
      this.SearchDto.BatchNo = undefined;
      this.SearchDto.TpsBatchNo = undefined;
      this.SearchDto.CustomerId = undefined;
      this.SearchDto.WithdrawOrderNo = undefined;
      this.SearchDto.IsWithdraw = undefined;
      this.SearchDto.Sorting = "";
      this.SearchDto.CreateDateRange = [];
      this.SearchDto.CompanyId = undefined;
      this.SearchDto.Status = undefined;
      this.SearchDto.FactoringStatus=undefined;
      this.SearchDto.UpdateDateRange = [];
      this.pageNumber = 1;
      this.getData();
    },
    //客户选择下拉改变事件
    customerSelectChange(value) {
      localStorage.setItem(customerIdLocalStorageKey, value);
    },
  },
  created() {
    const localStorageCustomerId = localStorage.getItem(
      customerIdLocalStorageKey
    );
    if (localStorageCustomerId) {
      this.SearchDto.CustomerId = localStorageCustomerId;
    }
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    this.initActionType();
    this.getAllCutomer();
    this.getAllPayCompany();
    this.setDefaultDateRange();
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
.gutter-row {
  padding: 0 0 10px 5px;
}
</style>
